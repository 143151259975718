import { ZERO_WEI } from '@dextoroprotocol/sdk/constants'
import { FuturesMarginType } from '@dextoroprotocol/sdk/types'
import { formatDollars } from '@dextoroprotocol/sdk/utils'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Row } from '@tanstack/react-table'
import ChangePercent from 'components/ChangePercent'
import ColoredPrice from 'components/ColoredPrice'
import Currency from 'components/Currency'
import { FlexDivRowCentered } from 'components/layout/flex'
import MarketBadge from 'components/MarketBadge'
import { DesktopOnlyView, MobileOrTabletView } from 'components/Media'
import Table, { compareNumericString, TableNoResults } from 'components/Table'
import { Body, NumericValue } from 'components/Text'
import { NO_VALUE } from 'constants/placeholder'
import ROUTES from 'constants/routes'
import Connector from 'containers/Connector'
import useIsL2 from 'hooks/useIsL2'
import useNetworkSwitcher from 'hooks/useNetworkSwitcher'
import PositionType from 'sections/futures/PositionType'
import { AppFuturesMarginType } from 'state/futures/common/types'
import { selectCrossMarginActivePositions } from 'state/futures/crossMargin/selectors'
import { selectMarkPrices } from 'state/futures/selectors'
import { selectSmartMarginActivePositions } from 'state/futures/smartMargin/selectors'
import { useAppSelector } from 'state/hooks'
import { selectOffchainPricesInfo } from 'state/prices/selectors'
import { getSynthDescription } from 'utils/futures'
import { weiSortingFn, alphabeticalSortingFn } from 'utils/table'

import { convertMarketName } from 'utils/formatters/marketName'

type FuturesPositionTableProps = {
	accountType: AppFuturesMarginType
	showCurrentMarket?: boolean
	showEmptyTable?: boolean
}

const sortBy = [{ id: 'market', desc: true }]

const FuturesPositionsTable: FC<FuturesPositionTableProps> = ({
	accountType,
	showCurrentMarket = true,
}) => {
	const { t } = useTranslation()
	const router = useRouter()
	const { switchToL2 } = useNetworkSwitcher()

	const { isWalletConnected } = Connector.useContainer()
	const isL2 = useIsL2()

	const { openConnectModal: connectWallet } = useConnectModal()

	const crossMarginPositions = useAppSelector(selectCrossMarginActivePositions)
	const smartMarginPositions = useAppSelector(selectSmartMarginActivePositions)
	const pricesInfo = useAppSelector(selectOffchainPricesInfo)
	const markPrices = useAppSelector(selectMarkPrices)

	let data = useMemo(() => {
		const positions =
			accountType === FuturesMarginType.SMART_MARGIN ? smartMarginPositions : crossMarginPositions
		return positions.map((position) => {
			const description = getSynthDescription(position.market.asset, t)
			const priceInfo = pricesInfo[position.market.asset]
			const marketPrice = markPrices[position.market.marketKey] ?? ZERO_WEI
			return {
				...position,
				description,
				marketPrice,
				priceInfo,
			}
		})
	}, [accountType, crossMarginPositions, markPrices, pricesInfo, smartMarginPositions, t])

	return (
		<>
			<DesktopOnlyView>
				<div>
					<Table
						data={data}
						columnVisibility={{ 'tp-sl': accountType === FuturesMarginType.SMART_MARGIN }}
						onTableRowClick={(row) =>
							router.push(ROUTES.Markets.MarketPair(row.original.market.asset, accountType))
						}
						noResultsMessage={
							!isL2 ? (
								<TableNoResults>
									{t('common.l2-cta')}
									{isWalletConnected ? (
										<div onClick={switchToL2}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
									) : (
										<div onClick={connectWallet}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
									)}
								</TableNoResults>
							) : (
								<TableNoResults>
									{!showCurrentMarket ? (
										t('dashboard.overview.futures-positions-table.no-result')
									) : (
										<>{t('common.perp-cta')}</>
									)}
								</TableNoResults>
							)
						}
						highlightRowsOnHover
						sortBy={sortBy}
						columns={[
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-positions-table.market')}
									</TableHeader>
								),
								id: 'market',
								accessorKey: 'market',
								cell: (cellProps) => {
									return (
										<MarketContainer>
											<IconContainer>
												<StyledCurrencyIcon currencyKey={cellProps.row.original.market.marketKey} />
											</IconContainer>
											<CellContainer>
												<StyledText>
													{convertMarketName(cellProps.row.original.market.marketName)}
													<MarketBadge
														currencyKey={cellProps.row.original.market.marketKey}
														isFuturesMarketClosed={cellProps.row.original.market.isSuspended}
														futuresClosureReason={cellProps.row.original.market.marketClosureReason}
													/>
												</StyledText>
												<StyledValue>
													<ColoredPrice priceChange={cellProps.row.original.priceInfo?.change} mono>
														{formatDollars(cellProps.row.original.marketPrice, {
															suggestDecimalsForAsset: cellProps.row.original.market.asset,
														})}
													</ColoredPrice>
												</StyledValue>
											</CellContainer>
										</MarketContainer>
									)
								},
								size: 180,
								enableSorting: true,
								sortingFn: weiSortingFn('marketPrice'),
							},
							{
								header: () => (
									<TableHeader>{t('dashboard.overview.futures-positions-table.side')}</TableHeader>
								),
								id: 'position',
								accessorKey: 'position',
								cell: (cellProps) => {
									return <PositionType side={cellProps.row.original.activePosition.side} />
								},
								size: 70,
								enableSorting: true,
								sortingFn: alphabeticalSortingFn('activePosition', 'side'),
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-positions-table.notionalValue')}
									</TableHeader>
								),
								id: 'notionalValue',
								accessorKey: 'notionalValue',
								cell: (cellProps) => {
									return (
										<Currency.Price
											mono
											price={cellProps.row.original.activePosition.notionalValue}
											formatOptions={{ truncateOver: 1e6 }}
										/>
									)
								},
								size: 90,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'notionalValue'),
							},
							{
								header: () => (
									<TableHeader>
										{t('dashboard.overview.futures-positions-table.avg-entry')}
									</TableHeader>
								),
								id: 'avgEntryPrice',
								accessorKey: 'avgEntryPrice',
								cell: (cellProps) => {
									return cellProps.row.original.activePosition.details?.avgEntryPrice ===
										undefined ? (
										<Body>{NO_VALUE}</Body>
									) : (
										<CellContainer>
											<Currency.Price
												price={cellProps.row.original.activePosition.details?.avgEntryPrice}
												formatOptions={{ suggestDecimals: true }}
												fontSize={12}
												mono
											/>
											<Currency.Price
												price={cellProps.row.original.activePosition.liquidationPrice}
												formatOptions={{ suggestDecimals: true }}
												colorType="secondary"
												fontSize={12}
												mono
											/>
										</CellContainer>
									)
								},
								size: 125,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'liquidationPrice'),
							},
							{
								header: () => (
									<TableHeader>{t('dashboard.overview.futures-positions-table.pnl')}</TableHeader>
								),
								id: 'pnl',
								accessorKey: 'pnl',
								cell: (cellProps) => {
									return (
										<CellContainer>
											<ChangePercent
												value={cellProps.row.original.activePosition.pnlPct}
												fontSize={12}
												mono
											/>
											<div>
												<Currency.Price
													price={cellProps.row.original.activePosition.pnl}
													fontSize={12}
													colored
													mono
												/>
											</div>
										</CellContainer>
									)
								},
								size: 125,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'pnl'),
							},
							{
								header: () => <TableHeader>TP/SL</TableHeader>,
								id: 'tp-sl',
								accessorKey: 'tp-sl',
								cell: (cellProps) => {
									return (
										<FlexDivRowCentered>
											<div style={{ marginRight: 10 }}>
												{cellProps.row.original.takeProfit === undefined ? (
													<Body mono>{NO_VALUE}</Body>
												) : (
													<div>
														<Currency.Price
															price={cellProps.row.original.takeProfit.targetPrice}
															formatOptions={{ suggestDecimals: true }}
															fontSize={12}
															mono
														/>
													</div>
												)}
												{cellProps.row.original.stopLoss === undefined ? (
													<Body mono>{NO_VALUE}</Body>
												) : (
													<div>
														<Currency.Price
															price={cellProps.row.original.stopLoss.targetPrice}
															formatOptions={{ suggestDecimals: true }}
															fontSize={12}
															mono
														/>
													</div>
												)}
											</div>
										</FlexDivRowCentered>
									)
								},
								size: 90,
								enableSorting: true,
								sortingFn: weiSortingFn('stopLoss'),
							},
							{
								header: () => <TableHeader>Market Margin</TableHeader>,
								id: 'margin',
								accessorKey: 'margin',
								cell: (cellProps) => {
									return (
										<FlexDivRowCentered>
											<div style={{ marginRight: 10 }}>
												<NumericValue
													value={cellProps.row.original.activePosition.initialMargin}
													mono
												/>
												<NumericValue
													value={cellProps.row.original.activePosition.leverage}
													color="secondary"
													suffix="x"
													mono
												/>
											</div>
										</FlexDivRowCentered>
									)
								},
								size: 115,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'initialMargin'),
							},
						]}
					/>
				</div>
			</DesktopOnlyView>
			<MobileOrTabletView>
				<MobileTableView>
					<Table
						data={data}
						columnVisibility={{ 'tp-sl': accountType === FuturesMarginType.SMART_MARGIN }}
						onTableRowClick={(row) =>
							router.push(ROUTES.Markets.MarketPair(row.original.market.asset, accountType))
						}
						noResultsMessage={
							!isL2 ? (
								<TableNoResults>
									{t('common.l2-cta')}
									{isWalletConnected ? (
										<div onClick={switchToL2}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
									) : (
										<div onClick={connectWallet}>{t('homepage.l2.cta-buttons.switch-l2')}</div>
									)}
								</TableNoResults>
							) : (
								<TableNoResults>
									{!showCurrentMarket ? (
										t('dashboard.overview.futures-positions-table.no-result')
									) : (
										<>{t('common.perp-cta')}</>
									)}
								</TableNoResults>
							)
						}
						highlightRowsOnHover
						sortBy={[{ id: 'market', desc: true }]}
						columns={[
							{
								header: () => (
									<TableHeaderMobile>
										{t('dashboard.overview.futures-positions-table.market')}
									</TableHeaderMobile>
								),
								id: 'market',
								accessorKey: 'market',
								cell: (cellProps) => {
									return (
										<MarketContainer>
											<Currency.Icon currencyKey={cellProps.row.original.market.marketKey} />
											<CellContainer>
												<StyledText>
													{convertMarketName(cellProps.row.original.market.marketName)}
													<MarketBadge
														currencyKey={cellProps.row.original.market.marketKey}
														isFuturesMarketClosed={cellProps.row.original.market.isSuspended}
														futuresClosureReason={cellProps.row.original.market.marketClosureReason}
													/>
												</StyledText>
												<StyledValue>
													<ColoredPrice
														priceChange={cellProps.row.original.priceInfo?.change}
														fontSize={12}
														mono
													>
														{formatDollars(cellProps.row.original.marketPrice, {
															suggestDecimalsForAsset: cellProps.row.original.market.asset,
														})}
													</ColoredPrice>
												</StyledValue>
											</CellContainer>
										</MarketContainer>
									)
								},
								size: 120,
								enableSorting: true,
							},
							{
								header: () => (
									<TableHeaderMobile>
										{t('dashboard.overview.futures-positions-table.side')}
									</TableHeaderMobile>
								),
								id: 'position',
								accessorKey: 'position',
								cell: (cellProps) => {
									return <PositionType side={cellProps.row.original.activePosition.side} />
								},
								size: 50,
								enableSorting: true,
							},
							{
								header: () => (
									<TableHeaderMobile>
										{t('dashboard.overview.futures-positions-table.pnl')}
									</TableHeaderMobile>
								),
								id: 'pnl',
								accessorKey: 'pnl',
								cell: (cellProps) => {
									return (
										<CellContainer>
											<ChangePercent
												value={cellProps.row.original.activePosition.pnlPct}
												fontSize={12}
												mono
											/>
											<div>
												<Currency.Price
													price={cellProps.row.original.activePosition.pnl}
													fontSize={12}
													mono
													colored
												/>
											</div>
										</CellContainer>
									)
								},
								size: 100,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'pnl'),
							},
							{
								header: () => <TableHeaderMobile>Market Margin</TableHeaderMobile>,
								id: 'margin',
								accessorKey: 'margin',
								cell: (cellProps) => {
									return (
										<div style={{ textAlign: 'right' }}>
											<NumericValue
												value={cellProps.row.original.activePosition.initialMargin}
												fontSize={12}
												mono
											/>
											<NumericValue
												value={cellProps.row.original.activePosition.leverage}
												color="secondary"
												suffix="x"
												fontSize={12}
												mono
											/>
										</div>
									)
								},
								size: 50,
								enableSorting: true,
								sortingFn: weiSortingFn('activePosition', 'initialMargin'),
							},
						]}
					/>
				</MobileTableView>
			</MobileOrTabletView>
		</>
	)
}

const MarketContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const CellContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 4px;
`

const StyledCurrencyIcon = styled(Currency.Icon)`
	width: 30px;
	height: 30px;
	margin-right: 8px;
`

const IconContainer = styled.div`
	grid-column: 1;
	grid-row: 1 / span 2;
`

const StyledValue = styled.div`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 12px;
	grid-column: 2;
	grid-row: 2;
`

const MobileTableView = styled.div`
	margin: 8px;
`

const TableHeader = styled(Body)`
	color: ${(props) => props.theme.colors.selectedTheme.gray};
	&:hover {
		color: ${(props) => props.theme.colors.selectedTheme.text.hoverMenuColor};
	}
`

const TableHeaderMobile = styled(TableHeader)`
	font-size: 12px;
`

const StyledText = styled.div`
	display: flex;
	align-items: center;
	grid-column: 2;
	grid-row: 1;
	margin-bottom: -4px;
	color: ${(props) => props.theme.colors.selectedTheme.newTheme.text.primaryWhite};
	font-family: ${(props) => props.theme.fonts.regular};
	font-size: 13px;
`

export default FuturesPositionsTable
